<template>
  <div
    v-if="loading"
    class="d-flex justify-content-center align-items-center vh-100"
  >
    <b-spinner variant="primary" label="Carregando..."></b-spinner>
  </div>
  <div v-else>
    <b-card class="mb-1">
      <b-form @submit.prevent="getData()">
        <div class="form-row">
          <div class="col-md-2">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.id"
                placeholder="ID"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.document_number"
                placeholder="Número do documento"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <v-select
                label="title"
                :searchable="false"
                :reduce="(option) => option.code"
                :options="optionsDocumentTypes"
                v-model="filters.document_type"
                placeholder="Tipo de documento"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="tel"
                v-model="filters.company"
                placeholder="Fornecedor"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsStatus"
                v-model="filters.status"
                placeholder="Status"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                :searchable="false"
                :clearable="true"
                item-text="title"
                item-value="code"
                :options="optionsSteps"
                v-model="filters.step"
                placeholder="Aprovação"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.period"
                class="form-control"
                placeholder="Período de criação"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-1 offset-md-1">
            <button
              :disabled="submitedFilter"
              type="submit"
              class="btn btn-primary btn-block"
            >
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <div
      v-if="
        $can('Para Aprovação - Aprovar', 'Financeiro - ADM') ||
        $can('Para Aprovação - Reprovar', 'Financeiro - ADM')
      "
      class="form-row justify-content-end mb-2"
    >
      <div
        class="col-md-2"
        v-if="$can('Para Aprovação - Reprovar', 'Financeiro - ADM')"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-warning btn-block"
          @click="changeStatus(2, 5)"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-down cursor-pointer cursor mr-1"
          ></i>
          Reprovar
        </button>
      </div>
      <div
        v-if="$can('Para Aprovação - Aprovar', 'Financeiro - ADM')"
        class="col-md-2"
      >
        <button
          :disabled="selectedItems.length < 1 ? true : false"
          type="button"
          class="btn btn-sm btn-success btn-block"
          @click="changeStatus(3, 2)"
        >
          <i
            style="font-size: 1.2rem; line-height: 1.5"
            class="bi bi-hand-thumbs-up cursor-pointer cursor mr-1"
          ></i>
          Aprovar
        </button>
      </div>
    </div>

    <b-card no-body>
      <b-table
        sticky-header
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template
          #head(select)=""
          v-if="$can('Para Aprovação - Aprovar', 'Financeiro - ADM')"
        >
          <b-form-checkbox
            v-model="selectAllCheckbox"
            @change="toggleSelectAll"
          />
        </template>

        <template
          #cell(select)="{ item }"
          v-if="$can('Para Aprovação - Aprovar', 'Financeiro - ADM')"
        >
          <b-form-checkbox
            v-if="
              item.status.title !== 'Reprovado' &&
              item.status.title !== 'Cancelado'
            "
            style="margin-left: 13px; padding: 0px; width: 20px; margin: auto"
            :value="item.uuid"
            v-model="selectedItems"
          />
        </template>

        <template #cell(supplier)="{ item }">
          <div class="strategic-units" v-if="item.supplier">
            <strong>{{ item.supplier.company_name }}</strong>
            <div>{{ item.supplier.document }}</div>
          </div>
          <div class="strategic-units" v-else>
            <strong>{{ item.customer.title }}</strong>
            <div>{{ toMaskCpf(item.customer.cpf) }}</div>
          </div>
        </template>

        <template #cell(plan)="{ item }">
          <div v-if="item.plan" class="d-block text-center">
            <strong>{{ item.plan.title }}</strong>
            <div v-if="item.document_type">{{ item.document_type.title }}</div>
          </div>
          <span v-else-if="item.order_id" class="text-danger">
            <strong class="d-block">Matrícula</strong>
            Cancelada
          </span>
        </template>

        <template #cell(user)="{ item }">
          {{ item.user.full_name }}
        </template>

        <template #cell(total)="{ item }">
          <div
            style="
              display: grid;
              align-content: center;
              align-items: center;
              grid-gap: 3px;
            "
          >
            <span>
              {{ item.total | toCurrency }}
            </span>
            <strong class="d-block" :class="getStatus(item.status.title)">
              {{ item.status.title }}
            </strong>
          </div>
        </template>

        <template #cell(step)="{ item }">
          <div
            v-if="item.step.current"
            class="d-flex justify-content-center align-items-center"
          >
            <div class="text-primary">
              <strong class="d-block">{{ item.step.current.title }}</strong>
              <span class="d-block" v-if="item.step.current.user_name">{{
                item.step.current.user_name
              }}</span>
            </div>
            <div>
              <feather-icon
                icon="ChevronsRightIcon"
                class="text-dark"
                size="16"
                style="margin: 0px 7px !important"
              />
            </div>
            <div class="text-success">
              <strong class="d-block">{{ item.step.next.title }}</strong>
              <span class="d-block" v-if="item.step.next.user_name">{{
                item.step.next.user_name
              }}</span>
            </div>
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <b-dropdown
            v-if="
              $can('Lançado - Editar', 'Financeiro - ADM') ||
              $can('Lançado - Visualizar', 'Financeiro - ADM')
            "
            size="sm"
            right
            variant="link"
            no-caret
            toggle-class="p-0"
            class="dropdown-actions"
          >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="15" variant="dark" />
            </template>

            <b-dropdown-item
              v-if="
                $can('Lançado - Editar', 'Financeiro - ADM') &&
                item.step.current.title !== 'Financeiro'
              "
              @click="
                $router.push({
                  name: 'orders-purchases-edit',
                  params: { uuid: item.uuid },
                })
              "
            >
              <feather-icon icon="EditIcon" class="cursor-pointer" size="15" />
              <span class="align-middle ml-50"
                >Editar {{ item.step.title }}</span
              >
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
              @click="
                $router.push({
                  name: 'orders-purchases-print',
                  params: { uuid: item.uuid },
                })
              "
            >
              <feather-icon
                @click="
                  $router.push({
                    name: 'orders-purchases-print',
                    params: { uuid: item.uuid },
                  })
                "
                v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
                icon="EyeIcon"
                class="cursor-pointer"
                size="15"
              />
              <span class="align-middle ml-50">Visualizar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- <template #cell(actions)="{ item }">
          <feather-icon
            @click="
              $router.push({
                name: 'orders-purchases-print',
                params: { uuid: item.uuid },
              })
            "
            v-if="$can('Lançado - Visualizar', 'Financeiro - ADM')"
            icon="EyeIcon"
            class="cursor-pointer"
            size="15"
          />
        </template> -->
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { maskCpf } from "@/_helpers/_urlHelper";

import { getStatusPurchaseOrderStatus } from "@/_helpers/_item";

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BTable,
    BMedia,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BSpinner,
    BLink,
    BDropdown,
    BDropdownItem,
    CustomPaginateTable,
    BDropdownDivider,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      selectedItems: [],
      selectAllCheckbox: false,
      submitedFilter: false,
      filters: {
        id: "",
        document_number: "",
        document_type: "",
        step: "",
        company: "",
        status: "",
        period: "",
        currentPage: 1,
        perPage: 100,
      },
      optionsStatus: [],
      optionsSteps: [],
      optionsDocumentTypes: [],
      totalRows: 0,
      rowsTable: 0,
      loading: false,
      tableColumns: [
        {
          key: "select",
          label: "",
          class: "text-center",
          thStyle: { width: "20px" },
        },
        {
          key: "id",
          label: "ID",
          class: "text-center",
          thStyle: { width: "50px" },
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: { width: "30px" },
        },
        { key: "supplier", label: "Fornecedor", class: "text-left" },
        {
          key: "plan",
          label: "Histórico PC",
          class: "text-center",
          thStyle: { width: "250px" },
        },
        {
          key: "total",
          label: "Total",
          class: "text-center",
          thStyle: { width: "130px" },
        },
        {
          key: "step",
          label: "Aprovação",
          class: "text-center",
          thStyle: { width: "230px" },
        },
        {
          key: "created_at",
          label: "Cadastro",
          class: "text-center",
          thStyle: { width: "130px" },
        },
      ],
      dataItems: [],
      itemsSelecteds: [],
      user: {},
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("userData"));
  },
  watch: {
    selectedItems(newVal) {
      if (newVal.length === 0 && this.dataItems.length === 0) {
        this.selectAllCheckbox = false;
      } else {
        this.selectAllCheckbox = newVal.length === this.dataItems.length;
      }
    },
  },
  methods: {
    getStatus(status) {
      return getStatusPurchaseOrderStatus(status);
    },
    toggleSelectAll() {
      this.selectedItems = this.selectAllCheckbox
        ? this.dataItems.map((item) => item.uuid)
        : [];
    },
    toMaskCpf(cpf) {
      return maskCpf(cpf);
    },
    changeStatus(step, status) {
      this.$swal({
        title: "Alterar status",
        text: "Você tem certeza que deseja alterar o status do pedido de compra?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, tenho!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("OrderPurchase/updatePurchaseOrderStep", {
              items: this.selectedItems,
              step: step,
              status: status,
            })
            .then((response) => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Pedido aprovado!",
                text: "Operação realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });

              if (response.moviment && response.moviment.id) {
                window.open(
                  this.$router.resolve({
                    name: "orders-purchases-romaneio-view",
                    params: { uuid: response.moviment.id },
                  }).href,
                  "_blank"
                );
              }
            });
        }
      });
    },
    async getData() {
      this.submitedFilter = true;

      const filtersData = {
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
        id: this.filters.id,
        status: this.filters.status ? this.filters.status.code : "",
        company: this.filters.company,
        step: this.filters.step ? this.filters.step.code : "",
        period: this.filters.period ? this.filters.period : "",
        document_type: this.filters.document_type
          ? this.filters.document_type.code
          : "",
        document_number: this.filters.document_number
          ? this.filters.document_number
          : "",
      };

      this.$store
        .dispatch("OrderPurchase/allApproved", filtersData)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;

            for (let a = 0; a < this.dataItems.length; a++) {
              for (let n = 0; n < this.optionsSteps.length; n++) {
                if (
                  this.dataItems[a].step.title === this.optionsSteps[n].title
                ) {
                  this.dataItems[a].step = {
                    current: this.dataItems[a].step,
                    next: {
                      title: this.optionsSteps[n + 1].title,
                      user_name: this.optionsSteps[n + 1].user_name,
                    },
                  };
                }
              }
            }
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.getData();

      const [steps, status, documentTypes] = await Promise.all([
        this.$store.dispatch("OrderPurchase/forSelectSteps"),
        this.$store.dispatch("OrderPurchase/forSelectStatus"),
        this.$store.dispatch("OrderPurchase/forSelectDocumentTypes"),
      ]);

      this.optionsSteps = steps;
      this.optionsStatus = status;
      this.optionsDocumentTypes = documentTypes;
    } catch (error) {
      this.notifyDefault("error", "Erro ao carregar dados");
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.strategic-units {
  align-items: center;
  display: grid;
  align-content: center;
  justify-content: left;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
